@import "/src/styles/index.scss";

.order-item {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 6px #e1e6ef;
  border-radius: 6px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    transition: 0.5s;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  .order-item-box {
    padding: 16px;
    padding-bottom: 0;
    // height: 130px;
    background: black;
    color: white;
    p {
      color: white;
    }
    h1 {
      color: white;
    }
    h3 {
      color: white;
    }
    h5 {
      color: white;
    }
  }
  .border-y {
    font-size: 12px;
    border-top: 1px solid #e8e8e8;
    padding: 0.5rem 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    .green {
      color: $green;
    }
    .yellow {
      color: $blue;
    }
    .zalo {
      color: #126af3;
    }
  }
  .order-item-moved {
    background-color: $red-color;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 4px 7px;
    span {
      margin-right: 5px;
    }
  }
  .order-item-header {
    @include flex-center();
    h3 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
    }
    h5 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      color: #e31837;
    }
  }
  .order-item-desc {
    border-bottom: 1px solid rgba(237, 237, 237, 0.15);

    @include flex-center();
    p {
      font-size: 12px;
      letter-spacing: 0px;
      color: #ededed;
    }
  }
  .order-item-time {
    border-top: 1px solid #e8e8e8;
    padding-top: 9px;
    @include flex-center();
    .order-item-desc {
      display: flex;
      align-items: center;
      img {
        padding: 3px;
        margin-right: 5px;
      }
      .order-shop {
        font-size: 24px;
        color: $white-color;
        padding: 3px;
        background-color: $green-color-3;
        margin-right: 5px;
      }
      .btn-order-cancel {
        width: 50px;
        height: 20px;
        background-color: $red-color;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0px;
        color: #ffffff;
        border-radius: 4px;
      }
    }
    .btn-order-confirm {
      border: 1px solid $gray-color !important;
      border-radius: 4px;
      font-size: 10px;
      letter-spacing: 0px;
      color: #414141;
      padding: 7px 10px;
      width: 72px;
      height: 30px;
      font-weight: 800;
    }
  }
  .order-item-voucher {
    border-top: 1px solid #e8e8e8;
    padding-top: 9px;
    @include flex-center();
  }
}

.notResponse {
  border: 2.5px solid red;
}
.font-bold-13 {
  font-weight: bold;
  font-size: 13px;
}

.green {
  color: #55c04a;
}
.flex-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-item-info {
  padding-bottom: 16px;
}
.order-item-info-light {
  padding-bottom: 16px;
}
.order-item-product-name {
  color: #ff9d00;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.order-item-product-name-light {
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.order-item-product-note {
  color: #fbbc05;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 5px;
  width: calc(100% - 48px);
  color: #4a505c;
  display: block;
}
.order-item-product-modifier {
  color: #fbbc05;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #4a505c;
  display: block;
}
.highLight {
  border: 2.5px solid orange;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: block;
  }
}

.order-item-box-light {
  background: white !important;
  color: black;
  border-radius: 8px;
}

.text-white {
  font-weight: 600;
  color: white;
  font-size: 14px;
}

.text-black {
  font-weight: 400;
  color: #000;
  font-size: 14px;
}
.order-item-product-notes-container {
  display: flex; /* Sắp xếp các phần tử ngang hàng */
  flex-wrap: wrap; /* Xuống dòng khi không đủ chỗ */
  gap: 8px; /* Khoảng cách giữa các phần tử (tùy chỉnh) */
}

.order-item-product-note {
  font-size: 14px;
}